import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const UnitedKingdom = () => {
    return (
        <Layout>
            <Seo title="United Kingdom" />

            <h1 className="title">United Kingdom</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">IP Searching</td>
                    </tr>

                    <tr>
                        <td>Ipsum (UK patent search)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ipo.gov.uk/p-ipsum.htm">https://www.ipo.gov.uk/p-ipsum.htm</a></td>
                    </tr>

                    <tr>
                        <td>SPC search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ipo.gov.uk/p-find-spc.htm">https://www.ipo.gov.uk/p-find-spc.htm</a></td>
                    </tr>

                    <tr>
                        <td>UKIPO trade mark search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.gov.uk/search-for-trademark">https://www.gov.uk/search-for-trademark</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Guidance and legislation</td>
                    </tr>

                    <tr>
                        <td>UK IPO Manual of Patent Practice</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.gov.uk/government/publications/patents-manual-of-patent-practice">https://www.gov.uk/government/publications/patents-manual-of-patent-practice</a></td>
                    </tr>

                    <tr>
                        <td>UK legislation</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.uk/">https://www.legislation.gov.uk/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Case law</td>
                    </tr>

                    <tr>
                        <td>British and Irish Legal Information Institute (BAILII)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.bailii.org/">https://www.bailii.org/</a></td>
                    </tr>

                    <tr>
                        <td>Swarb case law search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://swarb.co.uk/">https://swarb.co.uk/</a></td>
                    </tr>

                    <tr>
                        <td>Supreme Court case law search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.supremecourt.uk/decided-cases/">https://www.supremecourt.uk/decided-cases/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Pharma Resources</td>
                    </tr>

                    <tr>
                        <td>Electronic medicines compendium</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.medicines.org.uk/emc#gref">https://www.medicines.org.uk/emc#gref</a></td>
                    </tr>

                    <tr>
                        <td>MHRA SmPC, PIL and PAR search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://products.mhra.gov.uk/">https://products.mhra.gov.uk/</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default UnitedKingdom